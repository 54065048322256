$(function(){
    $('[data-toggle]').on('click', function(){
        const target = $(this).attr('href');
        if ($(target).attr('aria-hidden') === 'true') {
            $(target).attr('aria-hidden', 'false');
            $(target).addClass('js-visible');
            $(this).attr('aria-expanded', 'true');
        } else {
            $(target).attr('aria-hidden', 'true');
            $(this).attr('aria-expanded', 'false');
            $(target).removeClass('js-visible');
        }
        return false;
    });
});